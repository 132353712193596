var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "login-signup" } },
    [
      _c(
        "div",
        { staticClass: "inner-content" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "sidebar", attrs: { cols: "12", md: "5" } },
                [
                  _c("div", { staticClass: "login-message" }, [
                    _c("h2", { staticClass: "h_title_1" }, [
                      _vm._v(_vm._s(_vm.$t("loginSideTitle"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-16 white--text" }, [
                      _vm._v(_vm._s(_vm.$t("loginSideSubText"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.testimonial
                    ? _c("div", { staticClass: "quote-section" }, [
                        _c("label", [_vm._v(_vm._s(_vm.testimonial.text))]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt-5 authentic-profiles d-flex" },
                          [
                            _c("v-avatar", { attrs: { size: "50" } }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.getImage(
                                    _vm.testimonial.image + ".webp",
                                    _vm.testimonial.image + ".svg"
                                  ),
                                  height: "50",
                                  width: "50",
                                  contain: "",
                                  alt: "Testimonial Icon",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml-3" }, [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "font-weight-bold white--text text-16",
                                },
                                [_vm._v(_vm._s(_vm.testimonial.name))]
                              ),
                              _vm._v(" "),
                              _c("label", [
                                _vm._v(_vm._s(_vm.testimonial.role)),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  staticStyle: { position: "relative" },
                  attrs: { cols: "12", md: "7" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "content" },
                    [
                      _c("div", { staticClass: "d-flex align-center" }, [
                        _c("img", {
                          staticClass: "cursor-pointer",
                          attrs: {
                            src: require("assets/images/logo-text.svg"),
                            alt: "Logo Text",
                            width: "190",
                            height: "50",
                          },
                          on: {
                            click: function ($event) {
                              _vm.$router.push(_vm.localePath("/"))
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("v-main", [_c("Nuxt")], 1),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-footer",
                    { attrs: { absolute: "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-1" },
                        [
                          _c("v-col", { attrs: { cols: "12", sm: "5" } }, [
                            _c("label", { staticClass: "copyright" }, [
                              _vm._v(
                                "© Copyright " +
                                  _vm._s(new Date().getFullYear()) +
                                  " - HypeHeaven"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex justify-end",
                              attrs: { cols: "12", sm: "7" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex menu-item" },
                                [
                                  _c(
                                    "nuxt-link",
                                    { attrs: { to: _vm.localePath("/") } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("contactUs"))),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mx-3" }, [
                                    _vm._v("|"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    { attrs: { to: _vm.localePath("/") } },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("privacyPolicy"))),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mx-3" }, [
                                    _vm._v("|"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "nuxt-link",
                                    { attrs: { to: _vm.localePath("/") } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("termsAndConditions"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { value: _vm.overlay, opacity: "0.2" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }