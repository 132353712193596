
import { Component, Vue } from 'vue-property-decorator'
import { getImage } from "~/utils/ImageUtils"

@Component({
  layout: 'landing-page',
  head(this: LoginSignupLayout): object {
    if (process.env.NODE_ENV === 'development') {
      return {
        meta: [
          {
            name: 'robots',
            content: 'noindex'
          }
        ]
      };
    } else {
      return {};
    }
  }
})
export default class LoginSignupLayout extends Vue {
  overlay: boolean = false;
  testimonial: any = null;

  mounted() {
    const data = this.$t('testimonials') as any;
    if(data.length > 0) {
      this.testimonial = data[0];
    }
  }

  getImage(filename:any, fallback:any) {
    return getImage(filename, fallback);
  }
}
